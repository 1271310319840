/**
 * @class  JobOffers
 * This is ES6. Use Babel with Gulp.js.
 * global statusCodesHandler
 */


class JobOffers {

	constructor(_callback = () => {}){
		this.$JobOffersList = [];
		this.Jobs = [];
		this.Locations = [];
		this.JobTypes = [];
		this.selectedLocations = [];
		this.selectedJobTypes = [];
		this.serviceUrl = '/service.php';
		this.displayJobOffersList(() => {
			// this.displayLocations();
			this.displayJobTypes();
		});
		_callback();
	}

	/**
	 * Retrieves job offers by XHR sets this.Jobs with the result once it's done.
	 * @param  {Function} _success_callback Method to be executed on success.
	 * @param  {Function} _failure_callback Method to be executed on failure.
	 * @param  {Function} _always_callback  Method to be executed always. May fire before success oder failure.
	 * @return {Object}                   The instance.
	 * @chainable
	 */
	retrieveJobOffers(_success_callback, _failure_callback, _always_callback){
			this.Jobs = [];
		$.ajax({
			url: this.serviceUrl,
			data: {
				'fetch': 'jobs'
			},
			dataType: 'json',
			beforeSend: () => {
				// window.console.log('retrieveJobOffers()');
			},
			statusCode: this.statusCodesHandler()
		})
		.done((response) => {
			this.Jobs = response || [];
			if (typeof(_success_callback) === 'function') {
				_success_callback();
			}
		})
		.fail((jqXHR, textStatus, errorThrown) => {
			this.xhrResponseObject = jqXHR;
			if (typeof(_failure_callback) === 'function') {
				_failure_callback();
			}
		})
		.always(() => {
			if (typeof(_always_callback) === 'function') {
				_always_callback();
			}
		});
		return this;
	}

	/**
	 * Retrieves one specific job offer and sets this.Jobs with the result once it's done.
	 * @param  {Number} _job_id           The identifying number of the job offer in question.
	 * @param  {Function} _success_callback Method to be executed on success.
	 * @param  {Function} _failure_callback Method to be executed on failure.
	 * @param  {Function} _always_callback  Method to be executed always. May fire before success oder failure.
	 * @return {Object}                   The instance.
	 * * @chainable
	 */
	retrieveJobOfferDetails(_job_id, _success_callback, _failure_callback, _always_callback){
		$.ajax({
			url: this.serviceUrl,
			data: {
				'job': parseInt(_job_id, 10)
			},
			dataType: 'json',
			beforeSend: () => {
				// window.console.log('retrieveJobOfferDetails(_job_id: %d)', parseInt(_job_id, 10));
			},
			statusCode: this.statusCodesHandler()
		})
		.done((response) => {
			this.Jobs = response || [];
			if (typeof(_success_callback) === 'function') {
				_success_callback();
			}
		})
		.fail((jqXHR, textStatus, errorThrown) => {
			this.xhrResponseObject = jqXHR;
			if (typeof(_failure_callback) === 'function') {
				_failure_callback();
			}
		})
		.always(() => {
			if (typeof(_always_callback) === 'function') {
				_always_callback();
			}
		});
		return this;
	}

	/**
	 * Retrieves locations of all jobs offered. Sets this.Locations.
	 * @param  {Function} _success_callback Method to be executed on success.
	 * @param  {Function} _failure_callback Method to be executed on failure.
	 * @param  {Function} _always_callback  Method to be executed always. May fire before success oder failure.
	 * @return {Object}                   The instance.
	 * @chainable
	 */
	retrieveLocations(_success_callback, _failure_callback, _always_callback) {
		$.ajax({
			url: this.serviceUrl,
			data: {
				'fetch': 'locations'
			},
			dataType: 'json',
			beforeSend: () => {
				// window.console.log('retrieveLocations()');
			},
			statusCode: this.statusCodesHandler()
		})
		.done((response) => {
			this.Locations = response || [];
			if (typeof(_success_callback) === 'function') {
				_success_callback();
			}
		})
		.fail((jqXHR, textStatus, errorThrown) => {
			this.xhrResponseObject = jqXHR;
			if (typeof(_failure_callback) === 'function') {
				_failure_callback();
			}
		})
		.always(() => {
			if (typeof(_always_callback) === 'function') {
				_always_callback();
			}
		});
		return this;
	}

	/**
	 * Retrieves types of all jobs offered. Sets this.JobTypes.
	 * @param  {Function} _success_callback Method to be executed on success.
	 * @param  {Function} _failure_callback Method to be executed on failure.
	 * @param  {Function} _always_callback  Method to be executed always. May fire before success oder failure.
	 * @return {Object}                   The instance.
	 * @chainable
	 */
	retrieveJobTypes(_success_callback, _failure_callback, _always_callback) {
		$.ajax({
			url: this.serviceUrl,
			data: {
				'fetch': 'jobtypes'
			},
			dataType: 'json',
			beforeSend: () => {
				// window.console.log('retrieveJobTypes()');
			},
			statusCode: this.statusCodesHandler()
		})
		.done((response) => {
			this.JobTypes = response || [];
			if (typeof(_success_callback) === 'function') {
				_success_callback();
			}
		})
		.fail((jqXHR, textStatus, errorThrown) => {
			this.xhrResponseObject = jqXHR;
			if (typeof(_failure_callback) === 'function') {
				_failure_callback();
			}
		})
		.always(() => {
			if (typeof(_always_callback) === 'function') {
				_always_callback();
			}
		});
		return this;
	}

	clearJobOffersList(){
		if (this.$JobOffersList.length) {
			this.$JobOffersList.find('li.job_offer').remove();
		}
		return this;
	}

	/**
	 * Displays locations once retrieved.
	 * @return {Object} The instance.
	 * @chainable
	 */
	displayLocations(){
		this.retrieveLocations(() => {
			if (this.Locations.length) {
				if (this.$JobOffersList.length) {
					let $_ul = $('#job_offers').find('ul.locations');
					for (let i=0, max_i=this.Locations.length; i<max_i; i+=1) {
						let $_item = $('<li></li>').appendTo($_ul);
						$('<a></a>')
							.attr('href', '#job_offers:'+encodeURIComponent(this.Locations[i].region))
							.attr('title', 'Stellenangebote in '+this.Locations[i].region+' anzeigen.')
							.addClass('selected')
							.html('<span class="selection"> </span>'+this.Locations[i].region+' <span class="amount">'+this.Locations[i].amount+'</span>')
							.on('click', {self: this}, function(eventObject) {
								eventObject.data.self.toggleLocations(eventObject.data.self.Locations[i].region);
								$(this).toggleClass('selected');
								eventObject.preventDefault();
							}).appendTo($_item);
						this.selectedLocations.push(this.Locations[i].region);
					}
					// window.console.log(this.selectedLocations);
				}
			}
		});
		return this;
	}

	/**
	 * Display all job types once retrieved.
	 * @return {Object} The instance.
	 * @chainable
	 */
	displayJobTypes(){
		this.retrieveJobTypes(() => {
			if (this.JobTypes.length) {
				if (this.$JobOffersList.length) {
					let $_ul = $('#job_offers').find('ul.jobtypes');
					for (let i=0, max_i=this.JobTypes.length; i<max_i; i+=1) {
						let $_item = $('<li></li>').appendTo($_ul);
						$('<a></a>')
							.attr('href', '#job_offers:'+encodeURIComponent(this.JobTypes[i].jobtype))
							.attr('title', 'Stellenangebote für ›'+this.JobTypes[i].jobtype+'‹ anzeigen.')
							.addClass('jobtype')
							.html('<span class="selection"> </span>'+this.JobTypes[i].jobtype+' <span class="amount">'+this.JobTypes[i].amount+'</span>')
							.attr('data-jobtype', encodeURIComponent(this.JobTypes[i].jobtype))
							.on('click', {self: this}, function(eventObject) {
								eventObject.data.self.selectJobType(eventObject.data.self.JobTypes[i].jobtype);
								eventObject.preventDefault();
							}).appendTo($_item);
					}
					this.selectAllJobTypes();
				}
			}
		});
		return this;
	}

	/**
	 * Filters the display by a selected location. The location string is transformed with encodeURIComponent().
	 * @param  {String} _location The location in question.
	 * @return {Object}           The instance.
	 * @chainable
	 */
	toggleLocations(_location = '') {
		if (_location.length) {
			let index = this.selectedLocations.indexOf(_location);
			if (index !== -1) {
				this.selectedLocations.splice(index, 1);
				$("li.job_offer[data-location='"+encodeURIComponent(_location)+"']").hide();
			} else {
				this.selectedLocations.push(_location);
				$("li.job_offer[data-location='"+encodeURIComponent(_location)+"']").show();
			}
		}
		return this;
	}

	/**
	 * Filters the dipslay by a selected job type. The type string is transformed with encodeURIComponent().
	 * @param  {String} _jobtype The type in question.
	 * @return {Object}          The instance.
	 * @chainable
	 */
	toggleJobTypes(_jobtype = '') {
		if (_jobtype.length) {
			let index = this.selectedJobTypes.indexOf(_jobtype);
			if (index !== -1) {
				this.selectedJobTypes.splice(index, 1);
				$("li.job_offer[data-jobtype='"+encodeURIComponent(_jobtype)+"']").hide().find('a').removeClass('selected');
				$("a.jobtype[data-jobtype='"+encodeURIComponent(_jobtype)+"']").addClass('selected');
			} else {
				this.selectedJobTypes.push(_jobtype);
				$("li.job_offer[data-jobtype='"+encodeURIComponent(_jobtype)+"']").show().find('a').addClass('selected');
				$("a.jobtype[data-jobtype='"+encodeURIComponent(_jobtype)+"']").removeClass('selected');
			}
		}
		return this;
	}

	/**
	 * Selects a job type by pushing the given job type into the array
	 * of #selectedJobTypes, showing the corresponding job offers
	 * and marking the tag as selected.
	 * @param  {String} _jobtype The job type of this name.
	 * @return {Object}          The instance.
	 * @chainable
	 */
	selectJobType(_jobtype = '') {
		if (_jobtype.length) {
			if (this.selectedJobTypes.length === $('ul.jobtypes').find('a.jobtype').length) {
				this.unselectAllJobTypes();
			}
			if (this.selectedJobTypes.indexOf(_jobtype) === -1) {
				this.selectedJobTypes.push(_jobtype);
				$("li.job_offer[data-jobtype='"+encodeURIComponent(_jobtype)+"']").show();
				$("a.jobtype[data-jobtype='"+encodeURIComponent(_jobtype)+"']").addClass('selected');
			} else {
				this.unselectJobType(_jobtype);
			}
		}
		return this;
	}

	/**
	 * Unselecteds a job type with the given name
	 * @param  {String} _jobtype The job type of this name.
	 * @return {Object}          The instance.
	 * @chainable
	 */
	unselectJobType(_jobtype = ''){
		if (_jobtype.length) {
			let index = this.selectedJobTypes.indexOf(_jobtype);
			if (index !== -1) {
				this.selectedJobTypes.splice(index, 1);
				$("li.job_offer[data-jobtype='"+encodeURIComponent(_jobtype)+"']").hide();
				$("a.jobtype[data-jobtype='"+encodeURIComponent(_jobtype)+"']").removeClass('selected');
			}
		}
		return this;
	}

	/**
	 * Selects all job types regardless of their names or previous state.
	 * @return {Object}          The instance.
	 * @chainable
	 */
	selectAllJobTypes(){
		for (let i=0, max_i=this.JobTypes.length; i<max_i; i+=1) {
			this.selectJobType(this.JobTypes[i].jobtype);
		}
		return this;
	}

	/**
	 * Unselects all job types regardless of their name or previous state.
	 * @return {Object}          The instance.
	 * @chainable
	 */
	unselectAllJobTypes(){
		for (let i=0, max_i=this.JobTypes.length; i<max_i; i+=1) {
			this.unselectJobType(this.JobTypes[i].jobtype);
		}
		return this;
	}

	/**
	 * Displays all job offers as a list once retrieved.
	 * @param  {Function} _callback Callback method.
	 * @return {Object}           The instance.
	 * @chainable
	 */
	displayJobOffersList(_callback){
		// window.console.log('displayJobOffersList()');
		this.retrieveJobOffers(() => {
			if (this.Jobs.length) {
				if (!this.$JobOffersList.length) {
					this.$JobOffersList = $('#job_offers-list');
				} else {
					this.clearJobOffersList();
				}
				for (let i=0, max_i=this.Jobs.length; i<max_i; i+=1) {
					let $_item = this.$JobOffersList.find('li.template')
								.clone()
								.removeClass('template')
								.addClass('job_offer')
								.attr('data-job_id', this.Jobs[i].job_id)
								.attr('data-location', encodeURIComponent(this.Jobs[i].region))
								.attr('data-jobtype', encodeURIComponent(this.Jobs[i].job))
								.appendTo(this.$JobOffersList);
					let $_details_link = $_item.find('a.details');
					for (let property in this.Jobs[i]) {
						if (this.Jobs[i].hasOwnProperty(property)) {
							let $_placeholder = $_item.find('span.'+property);
							if ($_placeholder.length) {
								$_placeholder.html(this.Jobs[i][property]);
							}
						}
					}
					$_details_link.attr({
						'href': '#!/details/job:'+this.Jobs[i].job_id
					});
				}
			} else {
				this.displayNoOffersAvailable();
			}
			if (typeof(_callback) === 'function') {
				_callback();
			}
		}, () => {
			this.displayNoOffersAvailable();
		});
		return this;
	}

	/**
	 * Displays one job offer in detail.
	 * @param  {Number} _job_id The number identifying the job.
	 * @return {Object}         The instance.
	 * @chainable
	 */
	displayJobOfferDetails(_job_id = 0){
		if (parseInt(_job_id, 10) > 0) {
			this.retrieveJobOfferDetails(_job_id, () => {
				if (this.Jobs.length) {
					let dialogue = new window.ModalDialogue({
						before: () => {
							$('#main').addClass('blurred');
						},
						after: () => {
							$('#main').removeClass('blurred');
							window.location.hash = 'job_offers';
						},
						dialogueId: 'job_offers-details',
						dialogueTemplate: $('div.dialogue_wrapper-jobdetails.template'),
						isModal: false,
						isConfirmation: false,
						isAlert: false,
						callbackLocationHash: ''
					}).spawn(() => {
						// this.displayJobOffersList();
					})
					.addAction({
						label: 'Bewerben',
						href: '#!/application/job:'+_job_id,
						action: (eventObject) => {
							dialogue.destroy();
						}
					});
					let $_item = $('#job_offers-details');
					for (let property in this.Jobs[0]) {
						if (this.Jobs[0].hasOwnProperty(property)) {
							let $_placeholder = $_item.find('span.'+property);
							if ($_placeholder.length) {
								$_placeholder.html(this.Jobs[0][property]);
							}
						}
					}
					dialogue.show();
				}
			});
		}
		return this;
	}

	/**
	 * Display something meaningful once there ist nothing to display.
	 * @return {Object} The instance.
	 * @chainable
	 */
	displayNoOffersAvailable(){
		$('#job_offers').find('h2, h4, ul.jobtypes').hide();
		$('#job_offers-list').find('li.no_offers').show();
		return this;
	}

	/**
	 * Display a nice form to apply for a job.
	 * @param  {Number} _job_id The number identifying the job offer.
	 * @return {Object}         The instance.
	 * @chainable
	 */
	displayJobApplicationForm(_job_id = 0) {
		if (parseInt(_job_id, 10) > 0) {
			this.retrieveJobOfferDetails(_job_id, () => {
				$('#job_offers-application').remove();
				let dialogue = new window.ModalDialogue({
					before: () => {
						$('#main').addClass('blurred');
					},
					after: () => {
						$('#main').removeClass('blurred');
						window.location.hash = 'job_offers';
					},
					dialogueId: 'job_offers-application',
					dialogueTemplate: $('div.dialogue_wrapper-job_offers-application.template'),
					isModal: false,
					isConfirmation: false,
					isAlert: false,
					callbackLocationHash: '#!/'
				}).spawn(() => {
					let $_hook = $('#job_offers-application');
					for (let property in this.Jobs[0]) {
						if (this.Jobs[0].hasOwnProperty(property)) {
							let $_placeholder = $_hook.find('span.'+property);
							if ($_placeholder.length) {
								$_placeholder.html(this.Jobs[0][property]);
							}
						}
					}
				}).show();
				window.CF = new window.Contact({
					FormElement: document.getElementById('job-application-form'),
					transmissionTarget: '/Library/Services/JobApplicationService.php',
					transmissionMethod: 'POST'
				}).listen(
					() => {
						// before send
						document.getElementById('job-application-wrapper').classList.add('loading');
					},
					() => {
						// success
						document.getElementById('job-application-wrapper').classList.add('successful');
						document.getElementById('job-application-wrapper').classList.remove('loading');
					},
					() => {
						// failure
						document.getElementById('job-application-wrapper').classList.add('unsuccessful');
						document.getElementById('job-application-wrapper').classList.remove('loading');
					},
					() => {
						// always
					}
				);
				$('<input></input>')
					.attr({
						'required': 'required',
						'pattern': '[0-9]+',
						'type': 'hidden',
						'name': 'job',
						'id': 'job',
						'value': _job_id.toString()
					}).appendTo(window.CF.Form);
			});
		}
		return this;
	}

	/**
	 * This is a common handler for all XHR methods and handles several HTTP codes
	 * 200 - OK.
	 * 204 - No content.
	 * 302 - Found (but moved temporarily).
	 * 401 - Unauthorized.
	 * 402 - Payment required.
	 * 403 - Forbidden.
	 * 406 - Not acceptable.
	 * 500 - Internal server error (SNAFU).
	 * @method statusCodesHandler
	 * @return {Object} Error codes and its methods.
	 */
	statusCodesHandler(_callbackObject){
		return {
			200: () => {
				// window.console.log("statusCodesHandler: 200");
			},
			204: () => {
				// window.console.log("statusCodesHandler: 204");
				if ((typeof(_callbackObject) !== 'undefined') && (typeof(_callbackObject['204']) !== 'undefined')){
					if (typeof(_callbackObject) === 'function') {
						_callbackObject['204']();
					}
				}
			},
			302: () => {
				// window.console.log("statusCodesHandler: 302");
			},
			401: () => {
				// window.console.log("statusCodesHandler: 401");
			},
			402: () => {
				// window.console.log("statusCodesHandler: 402");
			},
			403: () => {
				// window.console.log("statusCodesHandler: 403");
			},
			406: () => {
				// window.console.log("statusCodesHandler: 406");
			},
			500: () => {
				// window.console.log("statusCodesHandler: 500");
			}
		};
	}
}