/* global ArgumentOptions */
/* global ActionButton */
/**
 * @class ModalDialogue
 * @description Create modal dialogues.
 */
class ModalDialogue extends ArgumentOptions {

	constructor(_options = null){
		super();
		this.$Dialogue  = [];
		this.Options = {
			'before': function(){},
			'after': function(){},
			'dialogueTemplate': $('div.dialogue_wrapper-standard.template'),
			'dialogueId': 'unknown',
			'isModal': false,
			'type': [
				'generic',
				'confirmation',
				'alert',
				'growl',
				'progress'
			],
			'isConfirmation': false,
			'isAlert': false,
			'Actions': [],
			'callbackLocationHash': ''
		};
		if ((_options !== null) && (typeof(_options) === 'object')) {
			this.setOptions(_options);
		}
	}

	/**
	 * Executes a function which has been attached to the Options object before showing the dialogue.
	 * @method before
	 * @return {Object}	The instance itself.
	 * @chainable
	 */
	before(){
		if (typeof(this.Options.before) === 'function') {
			this.Options.before();
		}
		return this;
	}

	/**
	 * Starts the whole thing.
	 * @method spawn
	 * @param  {Function} _callback Callback method.
	 * @return {Object}	The instance itself.
	 * @chainable
	 */
	spawn(_callback){
		this.$Dialogue 	= this.Options.dialogueTemplate
								.clone()
								.prependTo('body')
								.removeClass('template')
								.attr('id', this.Options.dialogueId);

		this.$Header 	= this.$Dialogue.find('.dialogue-header');
		this.$Body 		= this.$Dialogue.find('.dialogue-body');
		this.$Footer 	= this.$Dialogue.find('.dialogue-footer');

		this.before()
			.bindExitAction();

		if (this.Options.isConfirmation && this.Options.isAlert) {
			this.processPresets();
		}

		if (typeof(_callback) === 'function') {
			_callback();
		}
		return this;
	}

	/**
	 * If one suitable Option is set, we'll add som buttons.
	 * @method processPresets
	 * @return {Object}	The instance itself.
	 * @chainable
	 */
	processPresets() {
		if (this.Options.isConfirmation) {
			this.addAction({
				label: 'Fortfahren',
				action: () => {
					this.destroy();
				}
			}).addAction({
				label: 'Abbrechen',
				action: () => {
					this.destroy();
				},
				class: 'modest'
			});
		} else {
			if (this.Options.isAlert) {
				this.addAction({
					label: 'Fortfahren',
					action: () => {
						alert("Fortfahren"); //***
						this.destroy();
					}
				});
			}
		}
		return this;
	}

	/**
	 * If one suitable Option is set, we'll add som buttons.
	 * @method addAction
	 * @param {Object} _action The action represented as an Object.
	 * @return {Object}	The instance itself.
	 * @chainable
	 */
	addAction(_action){
		let action = {
			label: 'Label',
			href: '#',
			action: function(){},
			class: ''
		};
		if (typeof(_action) !== 'undefined') {
			for (let option in _action){
				if (_action.hasOwnProperty(option) && action.hasOwnProperty(option)) {
					action[option] = _action[option];
				}
			}
			action.Button = new ActionButton({
				Hook: this.$Footer,
				Label: action.label,
				Href: action.href,
				Action: action.action,
				Class: action.class
			}).render();
			this.Options.Actions.push(action);
		}
		return this;
	}

	/**
	 * Bind the exit action, a smoll x at the top left or right corner.
	 * @method bindExitAction
	 * @return {Object}	The instance itself.
	 * @chainable
	 */
	bindExitAction(){
		this.$Dialogue.find('a.dialogue-exit, a.exit').on('click', (eventObject) => {
			this.destroy();
			eventObject.preventDefault();
		});
		if (!this.Options.isModal) {
			this.$Dialogue.find('.dialogue_backdrop').on('click', (eventObject) => {
				this.destroy();
				eventObject.preventDefault();
			});
		}
		return this;
	}

	/**
	 * Shows the whole dialogue by adding .active to the dialogue element.
	 * @method show
	 * @return {Object}	The instance itself.
	 * @chainable
	 */
	show(){
		window.setTimeout(function(_self){
			_self.$Dialogue.addClass('active');
		}, 1, this);
		return this;
	}

	/**
	 * Removes the dialogue element from the DOM.
	 * @method destroy
	 * @return {Object}	The instance itself.
	 * @chainable
	 */
	destroy(_callback){
		this.$Dialogue.removeClass('active');
		this.$Dialogue.on('transitionend webkitTransitionEnd', (eventObject) => {
			this.$Dialogue.remove();

		});
		if (typeof(_callback) === 'function') {
			_callback();
		}
		this.after();
		return this;
	}

	/**
	 * Executes a function which has been attached to the Options object after destroying the dialogue.
	 * @method after
	 * @return {Object}	The instance itself.
	 * @chainable
	 */
	after(){
		if (typeof(this.Options.after) === 'function') {
			this.Options.after();
		}
		return this;
	}
}