class StatusCodesHandler {
	/**
	 * This is a common handler for all XHR methods and handles several HTTP codes
	 * 200 - OK.
	 * 204 - No content.
	 * 302 - Found (but moved temporarily).
	 * 401 - Unauthorized.
	 * 402 - Payment required.
	 * 403 - Forbidden.
	 * 406 - Not acceptable.
	 * 500 - Internal server error (SNAFU).
	 * @method statusCodesHandler
	 * @return {Object} Error codes and its methods.
	 */
	// constructor(_callbackObject) {
	// 	super();
	// 	return {
	// 		200: function(){
	// 			window.console.log("statusCodesHandler: 200");
	// 		},
	// 		204: function(){
	// 			window.console.log("statusCodesHandler: 204");
	// 			if ((typeof(_callbackObject) !== 'undefined') && (typeof(_callbackObject['204']) !== 'undefined')){
	// 				if (typeof(_callbackObject) === 'function') {
	// 					_callbackObject['204']();
	// 				}
	// 			}
	// 		},
	// 		302: function(){
	// 			window.console.log("statusCodesHandler: 302");
	// 		},
	// 		401: function(){
	// 			window.console.log("statusCodesHandler: 401");
	// 		},
	// 		402: function(){
	// 			window.console.log("statusCodesHandler: 402");
	// 		},
	// 		403: function(){
	// 			window.console.log("statusCodesHandler: 403");
	// 		},
	// 		406: function(){
	// 			window.console.log("statusCodesHandler: 406");
	// 		},
	// 		500: function(){
	// 			window.console.log("statusCodesHandler: 500");
	// 		}
	// 	};
	// }
}