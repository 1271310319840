/* global ModalDialogue */
/**
 * @class ActionButton
 * @description This object cares about actions and its buttons.
 */
class ActionButton extends ModalDialogue {

	constructor(_options){
		super();
		this.Options = {
			Template: $('div.dialogue_wrapper-standard.template a.button.template').clone(),
			Hook: {},
			Label: 'Unknown',
			Action: function(){},
			Href: '',
			Class: ''
		};
		this.setOptions(_options);
	}

	/**
	 * Renders a button by cloning a button from the template.
	 * @method render
	 * @return {Object} The instance itself.
	 * @chainable
	 */
	render() {
		this.Options.Template
			.removeClass('template')
			.attr('href', this.Options.Href)
			.attr('rel', 'nofollow')
			.html(this.Options.Label)
			.addClass(this.Options.Class)
			.on('click', this.Options.Action)
			.appendTo(this.Options.Hook);
		return this;
	}
}