/**
 * @class Contact
 *
 * This class is an abstraction for processing contact forms.
 * Takes the DOM element of the form argument.
 *
 * Usage:
  window.CF = new window.Contact({
    FormElement: document.getElementById('job-application-form'),
    transmissionTarget: '/Library/Services/JobApplicationService.php',
    transmissionMethod: 'POST'
  }).listen(
    () => {
      // before send
      document.getElementById('job-application-wrapper').classList.add('loading');
    },
    () => {
      // success
      document.getElementById('job-application-wrapper').classList.add('successful');
      document.getElementById('job-application-wrapper').classList.remove('loading');
    },
    () => {
      // failure
      document.getElementById('job-application-wrapper').classList.add('unsuccessful');
      document.getElementById('job-application-wrapper').classList.remove('loading');
    },
    () => {
      // always
    }
  );
 */
class Contact {

  /**
   * The constructor takes a DOM object as argument.
   * @constructor
   * @param  {Object} _form the DOM object of the form in question.
   * @return {Object}       Instance
   * @chainable
   */
  constructor(_options = {}) {
    this.Form = _options.FormElement;
    this.transmissionTarget = _options.transmissionTarget;
    this.transmissionMethod = _options.transmissionMethod;
    this.validFields = [];
    this.inValidFields = [];
    this.validResponseFields = [];
    this.inValidResponseFields = [];
    this.FormDataObject = {};
    this.Request = {};
    this.DelayAfterTransmit = 750; // ms
    this.Events = {
      beforeSend : function(){
      },
      success : function(){
      },
      failure : function(){
      },
      always : function(){
      }
    };

    (() => {
      if (this.Form.elements) {
        for (let i=0, max_i=this.Form.elements.length; i<max_i; i+=1) {
          this.Form.elements[i].classList.add('pristine');
        }
      }
    })();

    return this;
  }

  /**
   * Validates all form fields against their element attributes 'pattern'.
   * @method validate
   * @return {Bool} True if all fields meet their requirements.
   */
  validate() {
    // console.log("%cNow validating form.", "background-color: blue; color: white; padding: 5px;");
    this.validFields = [];
    this.inValidFields = [];
    for (let i=0, max_i=this.Form.elements.length; i<max_i; i+=1) {
      if (this.Form.elements[i].validity.valid === true) {
        // console.log("✅ ['%s']: \"%s\"", this.Form.elements[i].id, this.Form.elements[i].value);
        this.validFields.push(this.Form.elements[i].id);
      } else {
        // console.log("⛔️ ['%s']: \"%s\" patternMismatch: %s", this.Form.elements[i].id, this.Form.elements[i].value, this.Form.elements[i].validity.patternMismatch);
        this.inValidFields.push(this.Form.elements[i].id);
      }
    }
    if (this.inValidFields.length > 0) {
      this.markInvalidFields();
    } else {
      this.transmit();
    }
    return false;
  }

  /**
   * Adds an 'error' class to each invalid form element.
   * @method  markInvalidFields
   * @return {Object} Instance
   * @chainable
   */
  markInvalidFields() {
    for (let f=0, max_f=this.inValidFields.length; f<max_f; f+=1) {
      this.Form.elements[this.inValidFields[f]].classList.add('error');
    }
    return this;
  }

  /**
   * Uses XMLHttptRequest and FormData to transmit all form data.
   * @method transmit
   * @return {Object} Instance
   * @chainable
   */
  transmit() {
    this.Request = new window.XMLHttpRequest();
    this.Request.addEventListener('progress', (eventObject) => {
      // if (eventObject.lengthComputable) {
      //  console.log("Progress: %d.", eventObject.loaded/eventObject.total);
      // }
    }, false);
    this.Request.addEventListener('load', () => {
      if ((this.validateResponse(this.Request.response)) && (this.Request.status === 200)) {
        window.setTimeout(() => {
          this.Events.success();
        }, this.DelayAfterTransmit);
      } else {
        this.Events.failure();
      }
      this.Events.always();
    }, false);
    this.Request.addEventListener('error', (eventObject) => {
      window.console.warn('Transfer failed.');
    }, false);
    this.Request.addEventListener('abort', (eventObject) => {
      window.console.warn('Transfer cancelled.');
    }, false);
    let formData = new window.FormData();
    for (let i=0, max_i=this.Form.elements.length; i<max_i; i+=1) {
      formData.append(this.Form.elements[i].id, this.Form.elements[i].value);
    }
    this.Request.open(this.transmissionMethod, this.transmissionTarget, true);
    // this.Request.open('POST', 'http://httpbin.org/post', true);
    this.Request.send(formData);
    return this;
  }

  /**
   * Validates the server's response. The server needs to send all form values back for validation.
   * @method validateResponse
   * @param  {Object} _response Response
   * @return {Object} Instance
   * @chainable
   */
  validateResponse(_response = {}) {
    try {
      let response = JSON.parse(_response);
      if (typeof(response.FormFields) !== 'undefined') {
        for (let field in response.FormFields) {
          if (response.FormFields[field].value.replace(/\r?\n|\r/g, '') === this.Form.elements[field].value.replace(/\r?\n|\r/g, '')) {
            this.validResponseFields.push(field);
          } else {
            this.inValidResponseFields.push(field);
          }
        }
      }
      // window.console.log(this.inValidResponseFields, this.validResponseFields);
      if (!this.inValidResponseFields.length) {
        return true;
      }
    } catch(exception) {
      window.console.warn("validateResponse: Failed to parse JSON response from server.");
    }
    return false;
  }

  /**
   * Starts a listener to the submit event. Once the event is fired it will start the validation process.
   * @method  listen
   * @param  {Function} _beforesend_callback Callback method to be fired before transmitting the form data.
   * @param  {Function} _success_callback    Callback method to be fired once the transmit was successful.
   * @param  {Function} _failure_callback    Callback method to be fired once the transmit failed.
   * @param  {[type]}   _always_callback     Callback method to be fired once the transmit happened.
   * @return {Object}                        Instance
   * @chainable
   */
  listen(
    _beforesend_callback = () => {}
    , _success_callback = () => {}
    , _failure_callback = () => {}
    , _always_callback = () => {}) {
    this.Events = {
      beforeSend: _beforesend_callback,
      success: _success_callback,
      failure: _failure_callback,
      always: _always_callback
    };
    if (this.Form) {
      this.Form.addEventListener('submit', (eventObject) => {
        if (this.validate()) {
          this.Events.beforeSend();
        }
        eventObject.preventDefault();
      });
      for (let i=0, max_i=this.Form.elements.length; i<max_i; i+=1) {
        this.Form.elements[i].addEventListener('change', (eventObject) =>{
          this.Form.elements[i].classList.add('dirty');
          this.Form.elements[i].classList.remove('pristine');
        });
      }
    }
    return this;
  }
}