class FormTester {

	constructor() {
		this.Form = {};
		return this;
	}

	hasElements(_object = {}) {
		return (Object.keys(_object).length > 0);
	}

	fill(_formElements = {}) {
		// We will only run if the Form has been defined.
		if (this.hasElements(this.Form)) {
			for (let key in _formElements) {
				if (_formElements.hasOwnProperty(key)) {
					// window.console.log(`${key}: ${_formElements[key]}`);
					this.Form[key].value = _formElements[key];
					this.Form[key].classList.remove('pristine');
					this.Form[key].classList.add('dirty');
				}
			}
		}
		return this;
	}

	testContactForm() {
		this.Form = document.forms['contact-form'];
		this.fill({
			'fullname': 'Ottilie Normalverbraucherin',
			'emailaddress': 'you@me.com',
			'message': 'Ein kleines Bächlein namens Duden fließt durch ihren Ort und versorgt sie mit den nötigen Regelialien. Es ist ein paradiesmatisches Land, in dem einem gebratene Satzteile in den Mund fliegen. Nicht einmal von der allmächtigen Interpunktion werden die Blindtexte beherrscht – ein geradezu unorthographisches Leben.'
		});
		return this;
	}

	testApplicationForm() {
		this.Form = document.forms['job-application-form'];
		this.fill({
			'gender': 'f',
			'forename': 'Ottilie',
			'surname': 'Normalverbraucherin',
			'emailaddress': 'you@me.com',
			'phone': '+49123456789123',
			'message': 'Ein kleines Bächlein namens Duden fließt durch ihren Ort und versorgt sie mit den nötigen Regelialien. Es ist ein paradiesmatisches Land, in dem einem gebratene Satzteile in den Mund fliegen. Nicht einmal von der allmächtigen Interpunktion werden die Blindtexte beherrscht – ein geradezu unorthographisches Leben.'
		});
		return this;
	}
}