/**
 * ArgumentOptions is a tiny class for transferring argument properties
 * into instance Properties sorted into this.Options object.
 * It helps to have named properties.
 *
 * Usage: Just extend from this class while creating a new class.
 * Create a new instance of your class and pass an object as argument to
 * the constructor.
 */
class ArgumentOptions {

	constructor(){

	}

	/**
	 * Takes an object of options to set.
	 * @method setOptions
	 * @param {Object} _options Options.
	 * @return {Object}	The instance itself.
	 * @chainable
	 */
	setOptions(_options){
		if (typeof(_options) !== 'undefined') {
			for (var option in _options){
				if (_options.hasOwnProperty(option)) {
					this.Options[option] = _options[option];
				}
			}
		}
		return this;
	}
}