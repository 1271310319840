/**
 * Simple hashbang router for single page apps.
 *
 * Expects URIs like
 * 	#!/details/id:12
 * 	#!/buy/id:27
 * 	#!/bet/id:1456
 *
 * Initialize on DOMready:
 * window.LocationRouter = new Router({
 *		'bet' : function(_id){
 *			window.console.log("LocationRouter action triggered: 'bet()'.");
 *		},
 *		'details' : function(_id){
 *			window.console.log("LocationRouter action triggered: 'details()'.");
 *		},
 *		'buy' : function(_id){
 *			window.console.log("LocationRouter action triggered: 'buy()'.");
 *		}
 *	});
 * @param {Object} _router_actions This object takes the RouterActions as methods.
 */
class Router {

	constructor(_router_actions = {}) {
		this.availableRouterActions = [
			'details'
			, 'application'
			, 'contact'
		];
		this.registeredRouterActions = {};
		this.routerAction = '';

		if ((typeof(_router_actions) !== 'undefined') && ($.isPlainObject(_router_actions))) {
			for (let action in _router_actions) {
				if (_router_actions.hasOwnProperty(action)) {
					this.registerRouterAction(action, _router_actions[action]);
				}
			}
		}

		this.grepHashBang(() => {
			this.listen();
		});
	}

	/**
	 * Implements a Event Listener which watches the browser's address bar for changes.
	 * @return {Object} The instance itself.
	 */
	listen() {
		if ('onhashchange' in window) {
			window.addEventListener("hashchange", this.grepHashBang.bind(this), false);
		} else {
			// This browser does not support haschange event. If so it might fail at window.console as well.
		}
		return this;
	}

	/**
	 * Scrutinizes the hash part of window.location.
	 * @param  {Function} _callback Callback function.
	 * @return {Object}           The instance itself.
	 */
	grepHashBang(_callback = ()=>{}){
		// window.console.log("hashChange event fired. location.hash: %s", window.location.hash);
		let selectors = {
				'details' : window.location.hash.split('#!/details/')[1]
				, 'application' : window.location.hash.split('#!/application/')[1]
				, 'contact' : window.location.hash.split('#!/contact')[0]
			}
			, _id = '',
			idRegex = /^job:([0-9]+)/i;
		// window.console.log(this.registeredRouterActions);
		for (let selector in selectors) {
			if (selectors.hasOwnProperty(selector)) {
				if (typeof(selectors[selector]) !== 'undefined') {
					this.routerAction = selector;
					if (window.location.hash.split('#!/')[1] === selector) {
						if (typeof(this.registeredRouterActions[this.routerAction]) !== 'undefined') {
							this.registeredRouterActions[this.routerAction]();
						} else {
							// window.console.warn("The called RouterAction was not defined.");
						}
					} else {
						let id_grep = idRegex.exec(selectors[selector]);
						if (id_grep !== null) {
							_id = id_grep[1] || null;
							if (_id !== null) {
								this.Id = _id;
								if (typeof(this.registeredRouterActions[this.routerAction]) !== 'undefined') {
									this.registeredRouterActions[this.routerAction](this.Id);
								} else {
									// window.console.warn("The called RouterAction was not defined.");
								}
							}
						}
					}
				}
			}
		}
		if ($.isFunction(_callback)) {
			_callback();
		}
		return this;
	}

	/**
	 * Registers a given key with a given function. The key should be part of the
	 *  array this.availableRouterActions.
	 * @param  {String} _router_action The action's name.
	 * @param  {Function} _action_method What to do.
	 * @return {Object}                The instance itself.
	 */
	registerRouterAction(_router_action, _action_method) {
		if ($.inArray(_router_action, this.availableRouterActions) !== -1) {
			if ($.isFunction(_action_method)) {
				this.registeredRouterActions[_router_action] = _action_method;
			}
		}
		return this;
	}
}