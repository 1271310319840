/* global Image */
// DOMReady?
$(document).ready(function(){
	// Light the candle.
	//
	// Set up basic event handlers.
	$(document).ajaxStart(function(){
		$('html').attr('data-xhrstate', 'ajaxStart');
	});

	$(document).ajaxSend(function(){
		$('html').attr('data-xhrstate', 'ajaxSend');
	});

	$(document).ajaxSuccess(function(){
		$('html').attr('data-xhrstate', 'ajaxSuccess');
	});

	$(document).ajaxError(function(){
		$('html').attr('data-xhrstate', 'ajaxError');
	});

	$(document).ajaxComplete(function(){
		$('html').attr('data-xhrstate', 'ajaxComplete');
	});

	$(document).ajaxStop(function(){
		$('html').attr('data-xhrstate', 'ajaxStop');
	});

	window.J = new window.JobOffers();

	window.LocationRouter = new window.Router({
		'details' : function(_id){
			window.J.displayJobOfferDetails(_id);
		},
		'application' : function(_id){
			window.J.displayJobApplicationForm(_id);
		},
		'contact' : function(){
			window.CForm = new window.ContactForm();
			window.CForm.displayContactForm();
		}
	});
	window.setTimeout(function(){
		$('a.show_down').addClass('shown');
	}, 2000);

	$('a.callmodal').on('click', function(eventObject){
		let $MD = new window.ModalDialogue({
			before: function(){
				$('#main').addClass('blurred');
			},
			after: function(){
				$('#main').removeClass('blurred');
			},
			dialogueId: 'asta',
			isModal: false,
			isConfirmation: false,
			isAlert: true
		}).spawn();
		$('#'+$(this).attr('data-modal_content')).clone().appendTo($MD.$Body);
		$MD.$Header.find('h1').html($(this).attr('data-modal_headline'));
		$MD.$Body.find('.hidden').removeClass('hidden');
		$MD.$Body.find('.disposable').remove();
		// $MD.$Body.find('.col-lg-4').removeClass('col-lg-4').addClass('dialogue-body_columns');
		$MD.$Body.find('.col-lg-4').removeClass('col-lg-4');
		$MD.show();

		eventObject.preventDefault();
		window.MD = $MD;
	});
	$('a[href^="#"]').on('click',function(eventObject) {
		if (this.hash.length) {
			if (/^#!\//.test(this.hash)) {
				// eventObject.preventDefault();
			} else {
				let $target = $(this.hash);
				if ($target.length) {
					$('html, body').stop().animate({
						'scrollTop': $target.offset().top
					}, 500, 'swing', function() {});
				}
			}
		}
	});
	if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
		window.scrollTo(0, 0); // Fixes a Firefox scroll bug.
	}
});

window.onerror = function(_message, _url, _line) {
	window.console.log("Catching an error.");
	let img = new Image(),
		error = {
			'message': _message,
			'url': _url,
			'location_href': window.location.href,
			'line': _line
		};
	img.src = "service.php?jserror="+encodeURIComponent(JSON.stringify(error));
};