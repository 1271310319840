class LazyLoader {

	/**
	 * Usage:
	 * window.LL = new LazyLoader(document.querySelector('#job_offers'), function(){
	 * 	doSomething();
	 * });
	 */

	constructor(_element = {}, _callback = () => {}) {
		this.Element = _element;
		this.Callback = _callback;
		window.console.log("LazyLoader contructing.");
	}

	loadOnVisibility() {
		window.console.log("LazyLoader checking viewport.");
		if (this.elementInViewport(this.Element)) {
			this.Callback();
			this.Element.classList.add('lazyloaded');
			this.remove();
		}
		return this;
	}

	elementInViewport(_element = {}) {
		let rect = _element.getBoundingClientRect();

		return (
			rect.top >= 0
			&& rect.left >= 0
			&& rect.top <= (window.innerHeight || document.documentElement.clientHeight)
		);
	}

	listen() {

		window.console.log("LazyLoader listening.");

		window.addEventListener('scroll', () => {
			this.loadOnVisibility();
		}, false);
		return this;
	}

	remove() {
		window.removeEventListener('scroll', () => {}, false);
		return this;
	}
}