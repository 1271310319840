// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
let debounce = function(func, wait, immediate) {
	let timeout;
	return () => {
		let args = arguments,
			later = function() {
				timeout = null;
				if (!immediate) func.apply(this, args);
			},
			callNow = immediate && !timeout;
			window.clearTimeout(timeout);
			timeout = window.setTimeout(later, wait);
			if (callNow) func.apply(this, args);
	};
};

// You'll pass the debounce function the function to execute and the fire rate limit in milliseconds.  Here's an example usage:
//
// var myEfficientFn = debounce(function() {
// 	// All the taxing stuff you do
// }, 250);

// window.addEventListener('resize', myEfficientFn);