/**
 * @class ContactForm
 */
class ContactForm {

	constructor() {
		return this;
	}

	displayContactForm() {
		let dialogue = new window.ModalDialogue({
			before: () => {
				$('#main').addClass('blurred');
			},
			after: () => {
				$('#main').removeClass('blurred');
				window.location.hash = '';
			},
			dialogueId: 'contact-dialogue',
			dialogueTemplate: $('div.dialogue_wrapper-contact.template'),
			isModal: false,
			isConfirmation: false,
			isAlert: false,
			callbackLocationHash: '#!/'
		}).spawn(() => {
		}).show();
		window.CF = new window.Contact({
			FormElement: document.getElementById('contact-form'),
			transmissionTarget: '/Library/Services/ContactFormService.php',
			transmissionMethod: 'POST'
		});
		window.CF.listen(
			() => {
				// before send
				document.getElementById('contact-wrapper').classList.add('loading');
			},
			() => {
				// success
				document.getElementById('contact-wrapper').classList.add('successful');
				document.getElementById('contact-wrapper').classList.remove('loading');
				window.closeMessageTimeout = window.setTimeout(function(){
					dialogue.destroy();
				}, 10000);
			},
			() => {
				// failure
				document.getElementById('contact-wrapper').classList.add('unsuccessful');
				document.getElementById('contact-wrapper').classList.remove('loading');
				window.closeMessageTimeout = window.setTimeout(function(){
					dialogue.destroy();
				}, 30000);
			},
			() => {
				// always
			}
		);
		// .addAction({
		// 	label: 'Ihre Nachricht an uns',
		// 	href: '',
		// 	action: (eventObject) => {
		// 		dialogue.destroy();
		// 	}
		// })
		return this;
	}
}
